import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-072a01a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "results" }
const _hoisted_3 = { class: "order-header" }
const _hoisted_4 = { class: "order-primary-info" }
const _hoisted_5 = { class: "order-tags" }
const _hoisted_6 = { class: "order-metadata" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 0,
  class: "box-type desktop-only"
}
const _hoisted_9 = {
  key: 1,
  class: "box-type desktop-only"
}
const _hoisted_10 = { class: "order-item" }
const _hoisted_11 = { class: "product-info" }
const _hoisted_12 = { class: "overline" }
const _hoisted_13 = {
  key: 0,
  class: "desktop-only"
}
const _hoisted_14 = { class: "desktop-only ion-text-center" }
const _hoisted_15 = { class: "product-metadata" }
const _hoisted_16 = {
  key: 0,
  class: "kit-components"
}
const _hoisted_17 = {
  key: 1,
  class: "kit-components"
}
const _hoisted_18 = { class: "overline" }
const _hoisted_19 = { class: "mobile-only" }
const _hoisted_20 = { class: "actions" }
const _hoisted_21 = { class: "desktop-only" }
const _hoisted_22 = {
  key: 2,
  class: "empty-state"
}
const _hoisted_23 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ViewSizeSelector = _resolveComponent("ViewSizeSelector")!
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_DxpShopifyImg = _resolveComponent("DxpShopifyImg")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ViewSizeSelector, {
        "menu-id": "view-size-selector-inprogress",
        "content-id": "view-size-selector"
      }),
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_menu_button, {
            menu: "start",
            slot: "start"
          }),
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_menu_button, { slot: "start" }),
              (!_ctx.inProgressOrders.total)
                ? (_openBlock(), _createBlock(_component_ion_title, { key: 0 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.inProgressOrders.total) + " " + _toDisplayString(_ctx.translate('orders')), 1)
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_ion_title, { key: 1 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.inProgressOrders.query.viewSize) + " " + _toDisplayString(_ctx.translate('of')) + " " + _toDisplayString(_ctx.inProgressOrders.total) + " " + _toDisplayString(_ctx.translate('orders')), 1)
                    ]),
                    _: 1
                  })),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    disabled: !_ctx.hasPermission(_ctx.Actions.APP_RECYCLE_ORDER) || !_ctx.inProgressOrders.total || _ctx.isRejecting,
                    fill: "clear",
                    color: "danger",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.recycleInProgressOrders()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate("Reject all")), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled"]),
                  _createVNode(_component_ion_menu_button, {
                    menu: "view-size-selector-inprogress",
                    disabled: !_ctx.inProgressOrders.total
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.optionsOutline }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, {
        ref: "contentRef",
        "scroll-events": true,
        onIonScroll: _cache[8] || (_cache[8] = ($event: any) => (_ctx.enableScrolling())),
        id: "view-size-selector"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_searchbar, {
            class: "searchbar",
            placeholder: _ctx.translate('Search orders'),
            modelValue: _ctx.inProgressOrders.query.queryString,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inProgressOrders.query.queryString) = $event)),
            onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.updateQueryString($event.target.value)), ["enter"]))
          }, null, 8, ["placeholder", "modelValue"]),
          (_ctx.inProgressOrders.total)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_ion_radio_group, {
                  modelValue: _ctx.selectedPicklistId,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedPicklistId) = $event)),
                  onIonChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateSelectedPicklist($event.detail.value)))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_row, { class: "filters" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_radio, {
                              "label-placement": "end",
                              value: ""
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate('All')) + " ", 1),
                                    _createElementVNode("p", null, _toDisplayString(_ctx.translate('picklists', { count: _ctx.picklists.length })), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.picklists, (picklist) => {
                          return (_openBlock(), _createBlock(_component_ion_item, {
                            lines: "none",
                            key: picklist.id
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_radio, {
                                "label-placement": "end",
                                value: picklist.id
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(picklist.pickersName) + " ", 1),
                                      _createElementVNode("p", null, _toDisplayString(picklist.date), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["value"])
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_2, [
                  (!_ctx.isForceScanEnabled)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        expand: "block",
                        class: "bulk-action desktop-only",
                        fill: "outline",
                        size: "large",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.packOrders()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Pack orders")), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getInProgressOrders(), (order, index) => {
                    return (_openBlock(), _createBlock(_component_ion_card, {
                      class: _normalizeClass(["order", _ctx.isForceScanEnabled ? 'ion-margin-top' : '']),
                      key: index
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createElementVNode("strong", null, _toDisplayString(order.customerName), 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.translate("Ordered")) + " " + _toDisplayString(_ctx.formatUtcDate(order.orderDate, 'dd MMMM yyyy t a ZZZZ')), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _createElementVNode("div", _hoisted_5, [
                            _createVNode(_component_ion_chip, {
                              onClick: _withModifiers(($event: any) => (_ctx.orderActionsPopover(order, $event)), ["stop"]),
                              outline: ""
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.pricetagOutline }, null, 8, ["icon"]),
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(order.orderName), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_icon, { icon: _ctx.caretDownOutline }, null, 8, ["icon"])
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _createElementVNode("div", _hoisted_6, [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(order.shipmentMethodTypeDesc) + " ", 1),
                                (order.reservedDatetime)
                                  ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.translate("Last brokered")) + " " + _toDisplayString(_ctx.formatUtcDate(order.reservedDatetime, 'dd MMMM yyyy t a ZZZZ')), 1))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        ]),
                        (!order.shipmentPackages && !order.hasMissingInfo)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              _createVNode(_component_ion_skeleton_text, { animated: "" }),
                              _createVNode(_component_ion_skeleton_text, { animated: "" })
                            ]))
                          : (order.shipmentPackages)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                _createVNode(_component_ion_button, {
                                  disabled: _ctx.addingBoxForOrderIds.includes(order.orderId),
                                  onClick: _withModifiers(($event: any) => (_ctx.addShipmentBox(order)), ["stop"]),
                                  fill: "outline",
                                  shape: "round",
                                  size: "small"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, { icon: _ctx.addOutline }, null, 8, ["icon"]),
                                    _createTextVNode(_toDisplayString(_ctx.translate("Add Box")), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["disabled", "onClick"]),
                                _createVNode(_component_ion_row, null, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order.shipmentPackages, (shipmentPackage) => {
                                      return (_openBlock(), _createBlock(_component_ion_chip, {
                                        key: shipmentPackage.shipmentId,
                                        onClick: _withModifiers(($event: any) => (_ctx.updateShipmentBoxType(shipmentPackage, order, $event)), ["stop"])
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(`Box ${shipmentPackage?.packageName}`) + " " + _toDisplayString(shipmentPackage.shipmentBoxTypes.length ? `| ${_ctx.boxTypeDesc(_ctx.getShipmentPackageType(shipmentPackage))}` : '') + " ", 1),
                                          _createVNode(_component_ion_icon, { icon: _ctx.caretDownOutline }, null, 8, ["icon"])
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"]))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1024)
                              ]))
                            : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order.items, (item) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: item.orderItemSeqId,
                            class: "order-line-item"
                          }, [
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("div", _hoisted_11, [
                                _createVNode(_component_ion_item, { lines: "none" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_DxpShopifyImg, {
                                          src: _ctx.getProduct(item.productId).mainImageUrl,
                                          size: "small"
                                        }, null, 8, ["src"])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(item.productId))), 1),
                                        _createTextVNode(" " + _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) : item.productName) + " ", 1),
                                        (_ctx.isKit(item))
                                          ? (_openBlock(), _createBlock(_component_ion_badge, {
                                              key: 0,
                                              color: "dark"
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.translate("Kit")), 1)
                                              ]),
                                              _: 1
                                            }))
                                          : _createCommentVNode("", true),
                                        _createElementVNode("p", null, _toDisplayString(_ctx.getFeature(_ctx.getProduct(item.productId).featureHierarchy, '1/COLOR/')) + " " + _toDisplayString(_ctx.getFeature(_ctx.getProduct(item.productId).featureHierarchy, '1/SIZE/')), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              (!order.shipmentPackages && !order.hasMissingInfo)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                    _createVNode(_component_ion_item, { lines: "none" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_skeleton_text, {
                                          animated: "",
                                          style: {"width":"50%"}
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", _hoisted_14, [
                                _createElementVNode("div", null, [
                                  (item.rejectReason)
                                    ? (_openBlock(), _createBlock(_component_ion_chip, {
                                        key: 0,
                                        disabled: order.hasMissingInfo,
                                        outline: "",
                                        color: "danger",
                                        onClick: _withModifiers(($event: any) => (_ctx.removeRejectionReason($event, item, order)), ["stop"])
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_label, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.getRejectionReasonDescription(item.rejectReason)), 1)
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_ion_icon, { icon: _ctx.closeCircleOutline }, null, 8, ["icon"])
                                        ]),
                                        _: 2
                                      }, 1032, ["disabled", "onClick"]))
                                    : (_ctx.useNewRejectionApi() && _ctx.isEntierOrderRejectionEnabled(order))
                                      ? (_openBlock(), _createBlock(_component_ion_chip, {
                                          key: 1,
                                          disabled: order.hasMissingInfo,
                                          outline: "",
                                          color: "danger"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_label, null, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.getRejectionReasonDescription(_ctx.rejectEntireOrderReasonId) ? _ctx.getRejectionReasonDescription(_ctx.rejectEntireOrderReasonId) : _ctx.translate('Reject entire order')), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 2
                                        }, 1032, ["disabled"]))
                                      : (_openBlock(), _createBlock(_component_ion_chip, {
                                          key: 2,
                                          disabled: order.hasMissingInfo || !order.shipmentPackages || order.shipmentPackages.length === 0,
                                          outline: "",
                                          onClick: ($event: any) => (_ctx.openShipmentBoxPopover($event, item, item.orderItemSeqId, order))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(`Box ${item.selectedBox}`) + " ", 1),
                                            _createVNode(_component_ion_icon, { icon: _ctx.caretDownOutline }, null, 8, ["icon"])
                                          ]),
                                          _: 2
                                        }, 1032, ["disabled", "onClick"]))
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_15, [
                                (_ctx.isKit(item))
                                  ? (_openBlock(), _createBlock(_component_ion_button, {
                                      key: 0,
                                      fill: "clear",
                                      size: "small",
                                      onClick: _withModifiers(($event: any) => (_ctx.fetchKitComponents(item)), ["stop"])
                                    }, {
                                      default: _withCtx(() => [
                                        (item.showKitComponents)
                                          ? (_openBlock(), _createBlock(_component_ion_icon, {
                                              key: 0,
                                              color: "medium",
                                              slot: "icon-only",
                                              icon: _ctx.chevronUpOutline
                                            }, null, 8, ["icon"]))
                                          : (_openBlock(), _createBlock(_component_ion_icon, {
                                              key: 1,
                                              color: "medium",
                                              slot: "icon-only",
                                              icon: _ctx.listOutline
                                            }, null, 8, ["icon"]))
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true),
                                (item.productTypeId === 'GIFT_CARD')
                                  ? (_openBlock(), _createBlock(_component_ion_button, {
                                      key: 1,
                                      disabled: order.hasMissingInfo,
                                      color: "medium",
                                      fill: "clear",
                                      size: "small",
                                      onClick: ($event: any) => (_ctx.openGiftCardActivationModal(item))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_icon, {
                                          slot: "icon-only",
                                          icon: item.isGCActivated ? _ctx.gift : _ctx.giftOutline
                                        }, null, 8, ["icon"])
                                      ]),
                                      _: 2
                                    }, 1032, ["disabled", "onClick"]))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_ion_button, {
                                  disabled: order.hasMissingInfo,
                                  color: "danger",
                                  fill: "clear",
                                  size: "small",
                                  onClick: _withModifiers(($event: any) => (_ctx.openRejectReasonPopover($event, item, order)), ["stop"])
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, {
                                      slot: "icon-only",
                                      icon: _ctx.trashBinOutline
                                    }, null, 8, ["icon"])
                                  ]),
                                  _: 2
                                }, 1032, ["disabled", "onClick"]),
                                (_ctx.getProductStock(item.productId).quantityOnHandTotal)
                                  ? (_openBlock(), _createBlock(_component_ion_note, { key: 2 }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.getProductStock(item.productId).quantityOnHandTotal) + " " + _toDisplayString(_ctx.translate('pieces in stock')), 1)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : (_openBlock(), _createBlock(_component_ion_button, {
                                      key: 3,
                                      disabled: order.hasMissingInfo,
                                      color: "medium",
                                      fill: "clear",
                                      size: "small",
                                      onClick: _withModifiers(($event: any) => (_ctx.fetchProductStock(item.productId)), ["stop"])
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_icon, {
                                          slot: "icon-only",
                                          icon: _ctx.cubeOutline
                                        }, null, 8, ["icon"])
                                      ]),
                                      _: 2
                                    }, 1032, ["disabled", "onClick"]))
                              ])
                            ]),
                            (item.showKitComponents && !_ctx.getProduct(item.productId)?.productComponents)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                  _createVNode(_component_ion_item, { lines: "none" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_skeleton_text, {
                                        animated: "",
                                        style: {"height":"80%"}
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_item, { lines: "none" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_skeleton_text, {
                                        animated: "",
                                        style: {"height":"80%"}
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]))
                              : (item.showKitComponents && _ctx.getProduct(item.productId)?.productComponents)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getProduct(item.productId).productComponents, (productComponent, index) => {
                                      return (_openBlock(), _createBlock(_component_ion_card, { key: index }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_item, { lines: "none" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_DxpShopifyImg, {
                                                    src: _ctx.getProduct(productComponent.productIdTo).mainImageUrl,
                                                    size: "small"
                                                  }, null, 8, ["src"])
                                                ]),
                                                _: 2
                                              }, 1024),
                                              _createVNode(_component_ion_label, null, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(productComponent.productIdTo))), 1),
                                                  _createTextVNode(" " + _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(productComponent.productIdTo)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(productComponent.productIdTo)) : productComponent.productIdTo) + " ", 1),
                                                  _createElementVNode("p", null, _toDisplayString(_ctx.getFeature(_ctx.getProduct(productComponent.productIdTo).featureHierarchy, '1/COLOR/')) + " " + _toDisplayString(_ctx.getFeature(_ctx.getProduct(productComponent.productIdTo).featureHierarchy, '1/SIZE/')), 1)
                                                ]),
                                                _: 2
                                              }, 1024),
                                              (item.rejectReason || _ctx.isEntierOrderRejectionEnabled(order))
                                                ? (_openBlock(), _createBlock(_component_ion_checkbox, {
                                                    key: 0,
                                                    checked: item.rejectedComponents?.includes(productComponent.productIdTo),
                                                    onIonChange: ($event: any) => (_ctx.rejectKitComponent(order, item, productComponent.productIdTo))
                                                  }, null, 8, ["checked", "onIonChange"]))
                                                : _createCommentVNode("", true)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true)
                          ]))
                        }), 128)),
                        _createElementVNode("div", _hoisted_19, [
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_button, {
                                fill: "clear",
                                disabled: order.isModified || order.hasMissingInfo,
                                onClick: _withModifiers(($event: any) => (order.missingLabelImage ? _ctx.generateTrackingCodeForPacking(order) : _ctx.isForceScanEnabled ? _ctx.scanOrder(order) : _ctx.packOrder(order)), ["stop"])
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.translate("Pack using default packaging")), 1)
                                ]),
                                _: 2
                              }, 1032, ["disabled", "onClick"]),
                              _createVNode(_component_ion_button, {
                                slot: "end",
                                fill: "clear",
                                color: "medium",
                                onClick: _withModifiers(_ctx.packagingPopover, ["stop"])
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    slot: "icon-only",
                                    icon: _ctx.ellipsisVerticalOutline
                                  }, null, 8, ["icon"])
                                ]),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _createElementVNode("div", _hoisted_20, [
                          _createElementVNode("div", null, [
                            _createVNode(_component_ion_button, {
                              disabled: order.hasRejectedItem || order.isModified || order.hasMissingInfo,
                              onClick: _withModifiers(($event: any) => (order.missingLabelImage ? _ctx.generateTrackingCodeForPacking(order) : _ctx.isForceScanEnabled ? _ctx.scanOrder(order) : _ctx.packOrder(order)), ["stop"])
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("Pack")), 1)
                              ]),
                              _: 2
                            }, 1032, ["disabled", "onClick"]),
                            _createVNode(_component_ion_button, {
                              disabled: order.hasMissingInfo,
                              fill: "outline",
                              onClick: _withModifiers(($event: any) => (_ctx.save(order)), ["stop"])
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("Save")), 1)
                              ]),
                              _: 2
                            }, 1032, ["disabled", "onClick"])
                          ]),
                          _createElementVNode("div", _hoisted_21, [
                            (order.missingLabelImage)
                              ? (_openBlock(), _createBlock(_component_ion_button, {
                                  key: 0,
                                  fill: "outline",
                                  onClick: _withModifiers(($event: any) => (_ctx.showShippingLabelErrorModal(order)), ["stop"])
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("Shipping label error")), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["class"]))
                  }), 128)),
                  _withDirectives(_createVNode(_component_ion_infinite_scroll, {
                    onIonInfinite: _cache[6] || (_cache[6] = ($event: any) => (_ctx.loadMoreInProgressOrders($event))),
                    threshold: "100px",
                    ref: "infiniteScrollRef"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_infinite_scroll_content, {
                        "loading-spinner": "crescent",
                        "loading-text": _ctx.translate('Loading')
                      }, null, 8, ["loading-text"])
                    ]),
                    _: 1
                  }, 512), [
                    [_vShow, _ctx.isInProgressOrderScrollable()]
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.inProgressOrders.total)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (!_ctx.isForceScanEnabled)
                  ? (_openBlock(), _createBlock(_component_ion_fab, {
                      key: 0,
                      class: "mobile-only",
                      vertical: "bottom",
                      horizontal: "end",
                      slot: "fixed"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_fab_button, {
                          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.packOrders()))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, { icon: _ctx.checkmarkDoneOutline }, null, 8, ["icon"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ], 64))
            : (_openBlock(), _createElementBlock("div", _hoisted_22, [
                _createElementVNode("p", {
                  innerHTML: _ctx.getErrorMessage()
                }, null, 8, _hoisted_23)
              ]))
        ]),
        _: 1
      }, 512),
      (_ctx.selectedPicklistId)
        ? (_openBlock(), _createBlock(_component_ion_footer, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_buttons, { slot: "start" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        fill: "clear",
                        color: "primary",
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.openQRCodeModal(_ctx.selectedPicklistId)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "start",
                            icon: _ctx.qrCodeOutline
                          }, null, 8, ["icon"]),
                          _createTextVNode(" " + _toDisplayString(_ctx.translate("Generate QR code")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_buttons, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        fill: "outline",
                        color: "primary",
                        onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.editPickers(_ctx.getPicklist(_ctx.selectedPicklistId))))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "start",
                            icon: _ctx.pencilOutline
                          }, null, 8, ["icon"]),
                          _createTextVNode(" " + _toDisplayString(_ctx.translate("Edit Pickers")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_button, {
                        fill: "solid",
                        color: "primary",
                        onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.printPicklist(_ctx.getPicklist(_ctx.selectedPicklistId))))
                      }, {
                        default: _withCtx(() => [
                          (_ctx.getPicklist(_ctx.selectedPicklistId).isGeneratingPicklist)
                            ? (_openBlock(), _createBlock(_component_ion_spinner, {
                                key: 0,
                                slot: "start",
                                name: "crescent"
                              }))
                            : (_openBlock(), _createBlock(_component_ion_icon, {
                                key: 1,
                                slot: "start",
                                icon: _ctx.printOutline
                              }, null, 8, ["icon"])),
                          _createTextVNode(" " + _toDisplayString(_ctx.translate("Print Picklist")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}