import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-841bdb1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "order-header" }
const _hoisted_3 = { class: "order-primary-info" }
const _hoisted_4 = { class: "order-tags" }
const _hoisted_5 = { class: "order-metadata" }
const _hoisted_6 = { class: "order-header" }
const _hoisted_7 = { class: "order-primary-info" }
const _hoisted_8 = { class: "order-tags" }
const _hoisted_9 = { class: "order-metadata" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 0,
  class: "box-type desktop-only"
}
const _hoisted_13 = {
  key: 1,
  class: "box-type desktop-only"
}
const _hoisted_14 = { class: "order-item" }
const _hoisted_15 = { class: "product-info" }
const _hoisted_16 = { class: "overline" }
const _hoisted_17 = {
  key: 0,
  class: "desktop-only ion-text-center"
}
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "product-metadata" }
const _hoisted_20 = {
  key: 0,
  class: "kit-components"
}
const _hoisted_21 = { class: "overline" }
const _hoisted_22 = {
  key: 1,
  class: "mobile-only"
}
const _hoisted_23 = {
  key: 2,
  class: "mobile-only"
}
const _hoisted_24 = { class: "actions" }
const _hoisted_25 = {
  key: 2,
  class: "desktop-only"
}
const _hoisted_26 = {
  key: 0,
  class: "desktop-only"
}
const _hoisted_27 = { class: "shipgroup-details" }
const _hoisted_28 = { class: "ion-text-wrap" }
const _hoisted_29 = {
  key: 0,
  class: "ion-text-wrap"
}
const _hoisted_30 = { class: "ion-text-wrap" }
const _hoisted_31 = { class: "ion-text-wrap" }
const _hoisted_32 = {
  key: 1,
  class: "ion-text-wrap"
}
const _hoisted_33 = { class: "overline" }
const _hoisted_34 = { class: "overline" }
const _hoisted_35 = { class: "overline" }
const _hoisted_36 = {
  key: 0,
  class: "ion-padding-top ion-padding-start"
}
const _hoisted_37 = { class: "shipgroup-details" }
const _hoisted_38 = { class: "overline" }
const _hoisted_39 = { class: "overline" }
const _hoisted_40 = { class: "other-shipment-actions" }
const _hoisted_41 = {
  key: 0,
  class: "kit-components"
}
const _hoisted_42 = { class: "overline" }
const _hoisted_43 = {
  key: 1,
  class: "empty-state"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_DxpShopifyImg = _resolveComponent("DxpShopifyImg")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                slot: "start",
                "default-href": `/${_ctx.category}`
              }, null, 8, ["default-href"]),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Order details")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (Object.keys(_ctx.order).length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("h3", null, _toDisplayString(_ctx.order.orderName), 1)
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_ion_chip, {
                      outline: "",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyToClipboard(_ctx.order.orderId, 'Copied to clipboard')))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.pricetagOutline }, null, 8, ["icon"]),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.order.orderId), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    (_ctx.category !== 'open')
                      ? (_openBlock(), _createBlock(_component_ion_chip, {
                          key: 0,
                          outline: "",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.printPicklist(_ctx.order)))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, { icon: _ctx.documentTextOutline }, null, 8, ["icon"]),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate('Linked picklist')) + ": " + _toDisplayString(_ctx.order.picklistBinId), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.order?.orderPaymentPreferences?.length > 0)
                      ? (_openBlock(), _createBlock(_component_ion_chip, {
                          key: 1,
                          outline: "",
                          color: _ctx.statusColor[_ctx.order?.orderPaymentPreferences[0]?.statusId]
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, { icon: _ctx.cashOutline }, null, 8, ["icon"]),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate(_ctx.getPaymentMethodDesc(_ctx.order?.orderPaymentPreferences[0]?.paymentMethodTypeId))) + " : " + _toDisplayString(_ctx.translate(_ctx.getStatusDesc(_ctx.order?.orderPaymentPreferences[0]?.statusId))), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["color"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_ion_badge, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.category === 'open' ? _ctx.translate('Open') : (_ctx.category === 'in-progress' ? _ctx.translate('In Progress') : _ctx.translate('Completed'))), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createVNode(_component_ion_card, { class: "order" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createElementVNode("strong", null, _toDisplayString(_ctx.order.customerName), 1),
                            _createElementVNode("p", null, _toDisplayString(_ctx.translate("Ordered")) + " " + _toDisplayString(_ctx.formatUtcDate(_ctx.order.orderDate, 'dd MMMM yyyy t a ZZZZ')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_ion_chip, { outline: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, { icon: _ctx.ribbonOutline }, null, 8, ["icon"]),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.order.shipGroupSeqId), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.order.shipmentMethodTypeDesc) + " ", 1),
                            (_ctx.order.reservedDatetime)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.translate("Last brokered")) + " " + _toDisplayString(_ctx.formatUtcDate(_ctx.order.reservedDatetime, 'dd MMMM yyyy t a ZZZZ')), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    (_ctx.category === 'in-progress')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          (!_ctx.order.shipmentPackages && !_ctx.order.hasMissingInfo)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                _createVNode(_component_ion_skeleton_text, { animated: "" }),
                                _createVNode(_component_ion_skeleton_text, { animated: "" })
                              ]))
                            : (_ctx.order.shipmentPackages)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                  _createVNode(_component_ion_button, {
                                    disabled: _ctx.addingBoxForOrderIds.includes(_ctx.order.orderId),
                                    onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.addShipmentBox(_ctx.order)), ["stop"])),
                                    fill: "outline",
                                    shape: "round",
                                    size: "small"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, { icon: _ctx.addOutline }, null, 8, ["icon"]),
                                      _createTextVNode(" " + _toDisplayString(_ctx.translate("Add Box")), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["disabled"]),
                                  _createVNode(_component_ion_row, null, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.order.shipmentPackages, (shipmentPackage) => {
                                        return (_openBlock(), _createBlock(_component_ion_chip, {
                                          key: shipmentPackage.shipmentId,
                                          onClick: _withModifiers(($event: any) => (_ctx.updateShipmentBoxType(shipmentPackage, _ctx.order, $event)), ["stop"])
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(`Box ${shipmentPackage?.packageName}`) + " " + _toDisplayString(shipmentPackage.shipmentBoxTypes.length ? `| ${_ctx.boxTypeDesc(_ctx.getShipmentPackageType(shipmentPackage))}` : '') + " ", 1),
                                            _createVNode(_component_ion_icon, { icon: _ctx.caretDownOutline }, null, 8, ["icon"])
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  })
                                ]))
                              : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.order.items, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item,
                        class: "order-line-item"
                      }, [
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("div", _hoisted_15, [
                            _createVNode(_component_ion_item, { lines: "none" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_DxpShopifyImg, {
                                      src: _ctx.getProduct(item.productId).mainImageUrl,
                                      size: "small"
                                    }, null, 8, ["src"])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(item.productId))), 1),
                                    _createTextVNode(" " + _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) : _ctx.getProduct(item.productId).productName) + " ", 1),
                                    (_ctx.isKit(item))
                                      ? (_openBlock(), _createBlock(_component_ion_badge, {
                                          key: 0,
                                          color: "dark"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("Kit")), 1)
                                          ]),
                                          _: 1
                                        }))
                                      : _createCommentVNode("", true),
                                    _createElementVNode("p", null, _toDisplayString(_ctx.getFeature(_ctx.getProduct(item.productId).featureHierarchy, '1/COLOR/')) + " " + _toDisplayString(_ctx.getFeature(_ctx.getProduct(item.productId).featureHierarchy, '1/SIZE/')), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          (_ctx.category === 'in-progress')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                (item.rejectReason)
                                  ? (_openBlock(), _createBlock(_component_ion_chip, {
                                      key: 0,
                                      disabled: _ctx.order.hasMissingInfo,
                                      outline: "",
                                      color: "danger",
                                      onClick: _withModifiers(($event: any) => (_ctx.removeRejectionReason($event, item, _ctx.order)), ["stop"])
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_label, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.getRejectionReasonDescription(item.rejectReason)), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_ion_icon, { icon: _ctx.closeCircleOutline }, null, 8, ["icon"])
                                      ]),
                                      _: 2
                                    }, 1032, ["disabled", "onClick"]))
                                  : (_ctx.useNewRejectionApi() && _ctx.isEntierOrderRejectionEnabled(_ctx.order))
                                    ? (_openBlock(), _createBlock(_component_ion_chip, {
                                        key: 1,
                                        disabled: _ctx.order.hasMissingInfo,
                                        outline: "",
                                        color: "danger"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_label, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.getRejectionReasonDescription(_ctx.rejectEntireOrderReasonId) ? _ctx.getRejectionReasonDescription(_ctx.rejectEntireOrderReasonId) : _ctx.translate('Reject entire order')), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }, 8, ["disabled"]))
                                    : (_openBlock(), _createBlock(_component_ion_chip, {
                                        key: 2,
                                        disabled: _ctx.order.hasMissingInfo,
                                        outline: "",
                                        onClick: ($event: any) => (_ctx.openShipmentBoxPopover($event, item, _ctx.order))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_icon, { icon: _ctx.fileTrayOutline }, null, 8, ["icon"]),
                                          _createTextVNode(" " + _toDisplayString(`Box ${item.selectedBox || ''}`) + " ", 1),
                                          _createVNode(_component_ion_icon, { icon: _ctx.caretDownOutline }, null, 8, ["icon"])
                                        ]),
                                        _: 2
                                      }, 1032, ["disabled", "onClick"]))
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_18)),
                          _createElementVNode("div", _hoisted_19, [
                            (_ctx.getProductStock(item.productId).quantityOnHandTotal)
                              ? (_openBlock(), _createBlock(_component_ion_note, { key: 0 }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.getProductStock(item.productId).quantityOnHandTotal) + " " + _toDisplayString(_ctx.translate('pieces in stock')), 1)
                                  ]),
                                  _: 2
                                }, 1024))
                              : (_openBlock(), _createBlock(_component_ion_button, {
                                  key: 1,
                                  disabled: _ctx.order.hasMissingInfo,
                                  color: "medium",
                                  fill: "clear",
                                  size: "small",
                                  onClick: ($event: any) => (_ctx.fetchProductStock(item.productId))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate('Check stock')) + " ", 1),
                                    _createVNode(_component_ion_icon, {
                                      slot: "end",
                                      icon: _ctx.cubeOutline
                                    }, null, 8, ["icon"])
                                  ]),
                                  _: 2
                                }, 1032, ["disabled", "onClick"])),
                            (_ctx.category === 'in-progress')
                              ? (_openBlock(), _createBlock(_component_ion_button, {
                                  key: 2,
                                  disabled: _ctx.order.hasMissingInfo,
                                  onClick: ($event: any) => (_ctx.openRejectReasonPopover($event, item, _ctx.order)),
                                  class: "desktop-only",
                                  color: "danger",
                                  fill: "clear",
                                  size: "small"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate('Report an issue')) + " ", 1),
                                    _createVNode(_component_ion_icon, {
                                      slot: "end",
                                      icon: _ctx.trashBinOutline
                                    }, null, 8, ["icon"])
                                  ]),
                                  _: 2
                                }, 1032, ["disabled", "onClick"]))
                              : _createCommentVNode("", true),
                            (_ctx.isKit(item))
                              ? (_openBlock(), _createBlock(_component_ion_button, {
                                  key: 3,
                                  fill: "clear",
                                  color: "medium",
                                  size: "small",
                                  onClick: _withModifiers(($event: any) => (_ctx.fetchKitComponent(item)), ["stop"])
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate('Components')) + " ", 1),
                                    (item.showKitComponents)
                                      ? (_openBlock(), _createBlock(_component_ion_icon, {
                                          key: 0,
                                          color: "medium",
                                          slot: "icon-only",
                                          icon: _ctx.chevronUpOutline
                                        }, null, 8, ["icon"]))
                                      : (_openBlock(), _createBlock(_component_ion_icon, {
                                          key: 1,
                                          color: "medium",
                                          slot: "icon-only",
                                          icon: _ctx.listOutline
                                        }, null, 8, ["icon"]))
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              : _createCommentVNode("", true),
                            (item.productTypeId === 'GIFT_CARD')
                              ? (_openBlock(), _createBlock(_component_ion_button, {
                                  key: 4,
                                  disabled: _ctx.order.hasMissingInfo,
                                  fill: "clear",
                                  color: "medium",
                                  size: "small",
                                  onClick: ($event: any) => (_ctx.openGiftCardActivationModal(item))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate('Gift card')) + " ", 1),
                                    _createVNode(_component_ion_icon, {
                                      color: "medium",
                                      slot: "end",
                                      icon: item.isGCActivated ? _ctx.gift : _ctx.giftOutline
                                    }, null, 8, ["icon"])
                                  ]),
                                  _: 2
                                }, 1032, ["disabled", "onClick"]))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        (item.showKitComponents && _ctx.getProduct(item.productId)?.productComponents)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getProduct(item.productId).productComponents, (productComponent, index) => {
                                return (_openBlock(), _createBlock(_component_ion_card, { key: index }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_item, { lines: "none" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_DxpShopifyImg, {
                                              src: _ctx.getProduct(productComponent.productIdTo).mainImageUrl,
                                              size: "small"
                                            }, null, 8, ["src"])
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_ion_label, null, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(productComponent.productIdTo))), 1),
                                            _createTextVNode(" " + _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(productComponent.productIdTo)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(productComponent.productIdTo)) : productComponent.productIdTo) + " ", 1),
                                            (_ctx.isKit(item))
                                              ? (_openBlock(), _createBlock(_component_ion_badge, {
                                                  key: 0,
                                                  color: "dark"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.translate("Kit")), 1)
                                                  ]),
                                                  _: 1
                                                }))
                                              : _createCommentVNode("", true),
                                            _createElementVNode("p", null, _toDisplayString(_ctx.getFeature(_ctx.getProduct(productComponent.productIdTo).featureHierarchy, '1/COLOR/')) + " " + _toDisplayString(_ctx.getFeature(_ctx.getProduct(productComponent.productIdTo).featureHierarchy, '1/SIZE/')), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        (item.rejectReason || _ctx.isEntierOrderRejectionEnabled(_ctx.order))
                                          ? (_openBlock(), _createBlock(_component_ion_checkbox, {
                                              key: 0,
                                              checked: item.rejectedComponents?.includes(productComponent.productIdTo),
                                              onIonChange: ($event: any) => (_ctx.rejectKitComponent(_ctx.order, item, productComponent.productIdTo))
                                            }, null, 8, ["checked", "onIonChange"]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128)),
                    (_ctx.category === 'in-progress')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_button, {
                                fill: "clear",
                                disabled: _ctx.order.hasMissingInfo,
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.order.missingLabelImage ? _ctx.generateTrackingCodeForPacking(_ctx.order) : _ctx.isForceScanEnabled ? _ctx.scanOrder(_ctx.order) :_ctx.packOrder(_ctx.order)))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.translate("Pack using default packaging")), 1)
                                ]),
                                _: 1
                              }, 8, ["disabled"]),
                              _createVNode(_component_ion_button, {
                                slot: "end",
                                fill: "clear",
                                color: "medium",
                                onClick: _ctx.packagingPopover
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    slot: "icon-only",
                                    icon: _ctx.ellipsisVerticalOutline
                                  }, null, 8, ["icon"])
                                ]),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 1
                          })
                        ]))
                      : (_ctx.category === 'completed')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                            _createVNode(_component_ion_item, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_button, {
                                  disabled: _ctx.order.hasMissingShipmentInfo || _ctx.order.hasMissingPackageInfo || ((_ctx.isTrackingRequiredForAnyShipmentPackage(_ctx.order) && !_ctx.order.trackingCode) && !_ctx.hasPermission(_ctx.Actions.APP_FORCE_SHIP_ORDER)),
                                  fill: "clear"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("Ship Now")), 1)
                                  ]),
                                  _: 1
                                }, 8, ["disabled"]),
                                _createVNode(_component_ion_button, {
                                  slot: "end",
                                  fill: "clear",
                                  color: "medium",
                                  onClick: _withModifiers(_ctx.shippingPopover, ["stop"])
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, {
                                      slot: "icon-only",
                                      icon: _ctx.ellipsisVerticalOutline
                                    }, null, 8, ["icon"])
                                  ]),
                                  _: 1
                                }, 8, ["onClick"])
                              ]),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("div", null, [
                        (_ctx.category === 'in-progress')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createVNode(_component_ion_button, {
                                disabled: _ctx.order.hasRejectedItem || _ctx.order.isModified || _ctx.order.hasMissingInfo,
                                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.order.missingLabelImage ? _ctx.generateTrackingCodeForPacking(_ctx.order) : _ctx.isForceScanEnabled ? _ctx.scanOrder(_ctx.order) : _ctx.packOrder(_ctx.order)))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    slot: "start",
                                    icon: _ctx.personAddOutline
                                  }, null, 8, ["icon"]),
                                  _createTextVNode(" " + _toDisplayString(_ctx.translate("Pack order")), 1)
                                ]),
                                _: 1
                              }, 8, ["disabled"]),
                              _createVNode(_component_ion_button, {
                                disabled: _ctx.order.hasMissingInfo,
                                fill: "outline",
                                onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.save(_ctx.order)), ["stop"]))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.translate("Save")), 1)
                                ]),
                                _: 1
                              }, 8, ["disabled"])
                            ], 64))
                          : (_ctx.category === 'open')
                            ? (_openBlock(), _createBlock(_component_ion_button, {
                                key: 1,
                                onClick: _ctx.assignPickers
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    slot: "start",
                                    icon: _ctx.archiveOutline
                                  }, null, 8, ["icon"]),
                                  _createTextVNode(" " + _toDisplayString(_ctx.translate("Pick order")), 1)
                                ]),
                                _: 1
                              }, 8, ["onClick"]))
                            : (_ctx.category === 'completed')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                  (!_ctx.hasPackedShipments(_ctx.order))
                                    ? (_openBlock(), _createBlock(_component_ion_button, {
                                        key: 0,
                                        disabled: true
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_icon, {
                                            slot: "start",
                                            icon: _ctx.bagCheckOutline
                                          }, null, 8, ["icon"]),
                                          _createTextVNode(" " + _toDisplayString(_ctx.translate("Shipped")), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : (_openBlock(), _createBlock(_component_ion_button, {
                                        key: 1,
                                        disabled: _ctx.order.hasMissingShipmentInfo || _ctx.order.hasMissingPackageInfo || ((_ctx.isTrackingRequiredForAnyShipmentPackage(_ctx.order) && !_ctx.order.trackingCode) && !_ctx.hasPermission(_ctx.Actions.APP_FORCE_SHIP_ORDER)),
                                        onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.shipOrder(_ctx.order)), ["stop"]))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_icon, {
                                            slot: "start",
                                            icon: _ctx.bagCheckOutline
                                          }, null, 8, ["icon"]),
                                          _createTextVNode(" " + _toDisplayString(_ctx.translate("Ship order")), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["disabled"])),
                                  _createVNode(_component_ion_button, {
                                    disabled: _ctx.order.hasMissingShipmentInfo || _ctx.order.hasMissingPackageInfo,
                                    fill: "outline",
                                    onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.printPackingSlip(_ctx.order)), ["stop"]))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.translate("Print Customer Letter")) + " ", 1),
                                      (_ctx.order.isGeneratingPackingSlip)
                                        ? (_openBlock(), _createBlock(_component_ion_spinner, {
                                            key: 0,
                                            "data-spinner-size": "medium",
                                            color: "primary",
                                            slot: "end",
                                            name: "crescent"
                                          }))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  }, 8, ["disabled"])
                                ]))
                              : _createCommentVNode("", true)
                      ]),
                      (_ctx.category === 'completed')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                            _createVNode(_component_ion_button, {
                              disabled: !_ctx.hasPermission(_ctx.Actions.APP_UNPACK_ORDER) || _ctx.order.hasMissingShipmentInfo || _ctx.order.hasMissingPackageInfo || !_ctx.hasPackedShipments(_ctx.order),
                              fill: "outline",
                              color: "danger",
                              onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_ctx.unpackOrder(_ctx.order)), ["stop"]))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("Unpack")), 1)
                              ]),
                              _: 1
                            }, 8, ["disabled"])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_27, [
                  _createVNode(_component_ion_card, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_header, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate('Destination')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, { lines: "none" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createElementVNode("h3", null, _toDisplayString(_ctx.order?.shippingAddress?.toName), 1),
                              _createElementVNode("p", _hoisted_28, _toDisplayString(_ctx.order?.shippingAddress?.address1), 1),
                              (_ctx.order?.shippingAddress?.address2)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_29, _toDisplayString(_ctx.order.shippingAddress.address2), 1))
                                : _createCommentVNode("", true),
                              _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.order?.shippingAddress?.city ? _ctx.order.shippingAddress.city + "," : "") + " " + _toDisplayString(_ctx.order.shippingAddress?.postalCode), 1),
                              _createElementVNode("p", _hoisted_31, _toDisplayString(_ctx.order?.shippingAddress?.stateName ? _ctx.order.shippingAddress.stateName + "," : "") + " " + _toDisplayString(_ctx.order.shippingAddress?.countryName), 1),
                              (_ctx.order?.contactNumber)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_32, _toDisplayString(_ctx.order?.contactNumber), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_ctx.order?.shippingInstructions)
                        ? (_openBlock(), _createBlock(_component_ion_item, {
                            key: 0,
                            color: "light",
                            lines: "none"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("p", _hoisted_33, _toDisplayString(_ctx.translate("Handling Instructions")), 1),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.order?.shippingInstructions ? _ctx.order.shippingInstructions : 'Sample Handling instructions'), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  (['PICKITEM_PENDING', 'PICKITEM_PICKED', 'PICKITEM_COMPLETED'].includes(_ctx.order?.items[0]?.picklistItemStatusId))
                    ? (_openBlock(), _createBlock(_component_ion_card, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card_header, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card_title, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.translate('Shipment method')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_select, {
                                disabled: !_ctx.order.missingLabelImage,
                                label: _ctx.translate('Carrier'),
                                modelValue: _ctx.carrierPartyId,
                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.carrierPartyId) = $event)),
                                interface: "popover",
                                onIonChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.updateCarrierAndShippingMethod(_ctx.carrierPartyId, '')))
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.facilityCarriers, (carrier) => {
                                    return (_openBlock(), _createBlock(_component_ion_select_option, {
                                      key: carrier.partyId,
                                      value: carrier.partyId
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate(carrier.groupName)), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["disabled", "label", "modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              (_ctx.carrierMethods && _ctx.carrierMethods.length > 0)
                                ? (_openBlock(), _createBlock(_component_ion_select, {
                                    key: 0,
                                    disabled: !_ctx.order.missingLabelImage,
                                    label: _ctx.translate('Method'),
                                    modelValue: _ctx.shipmentMethodTypeId,
                                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.shipmentMethodTypeId) = $event)),
                                    interface: "popover",
                                    onIonChange: _cache[12] || (_cache[12] = ($event: any) => (_ctx.updateCarrierAndShippingMethod(_ctx.carrierPartyId, _ctx.shipmentMethodTypeId)))
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.carrierMethods, (method) => {
                                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                                          key: method.productStoreShipMethId,
                                          value: method.shipmentMethodTypeId
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate(method.description)), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["value"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["disabled", "label", "modelValue"]))
                                : (!_ctx.isUpdatingCarrierDetail)
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.translate('No shipment methods linked to', {carrierName: _ctx.getCarrierName(_ctx.carrierPartyId)})), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_button, {
                                        onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.openShippingMethodDocumentReference())),
                                        fill: "clear",
                                        color: "medium",
                                        slot: "end"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_icon, {
                                            slot: "icon-only",
                                            icon: _ctx.informationCircleOutline
                                          }, null, 8, ["icon"])
                                        ]),
                                        _: 1
                                      })
                                    ], 64))
                                  : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }),
                          (_ctx.order.missingLabelImage)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                (_ctx.category === 'completed')
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _createVNode(_component_ion_button, {
                                        disabled: !_ctx.shipmentMethodTypeId,
                                        fill: "outline",
                                        expand: "block",
                                        class: "ion-margin",
                                        onClick: _cache[14] || (_cache[14] = _withModifiers(($event: any) => (_ctx.regenerateShippingLabel(_ctx.order)), ["stop"]))
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.shipmentLabelErrorMessages ? _ctx.translate("Retry Label") : _ctx.translate("Generate Label")) + " ", 1),
                                          (_ctx.order.isGeneratingShippingLabel)
                                            ? (_openBlock(), _createBlock(_component_ion_spinner, {
                                                key: 0,
                                                color: "primary",
                                                slot: "end",
                                                "data-spinner-size": "medium",
                                                name: "crescent"
                                              }))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 1
                                      }, 8, ["disabled"]),
                                      _createVNode(_component_ion_button, {
                                        disabled: !_ctx.shipmentMethodTypeId || !_ctx.carrierPartyId,
                                        fill: "clear",
                                        expand: "block",
                                        color: "medium",
                                        onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.openTrackingCodeModal()))
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.translate("Add tracking code manually")), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["disabled"])
                                    ], 64))
                                  : _createCommentVNode("", true),
                                (_ctx.shipmentLabelErrorMessages)
                                  ? (_openBlock(), _createBlock(_component_ion_item, {
                                      key: 1,
                                      lines: "none"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.shipmentLabelErrorMessages), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true)
                              ], 64))
                            : (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.order.trackingCode) + " ", 1),
                                      _createElementVNode("p", null, _toDisplayString(_ctx.translate("tracking code")), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_button, {
                                    disabled: _ctx.order.hasMissingInfo,
                                    slot: "end",
                                    fill: "clear",
                                    color: "medium",
                                    onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.shippingLabelActionPopover($event, _ctx.order)))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, {
                                        slot: "icon-only",
                                        icon: _ctx.ellipsisVerticalOutline
                                      }, null, 8, ["icon"])
                                    ]),
                                    _: 1
                                  }, 8, ["disabled"])
                                ]),
                                _: 1
                              }))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.hasPermission(_ctx.Actions.APP_INVOICING_STATUS_VIEW) && (_ctx.category === 'completed') && _ctx.orderInvoicingInfo.id)
                    ? (_openBlock(), _createBlock(_component_ion_card, { key: 1 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card_header, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card_title, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.translate("Order Invoicing Status")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          (_ctx.orderInvoicingInfo.invoicingConfirmationDate)
                            ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", _hoisted_34, _toDisplayString(_ctx.getInvoicingConfirmationDate(_ctx.orderInvoicingInfo.invoicingConfirmationDate)), 1),
                                      _createTextVNode(" " + _toDisplayString(_ctx.translate("Retail Pro invoicing confirmed")), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_ion_item, { lines: "none" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("p", _hoisted_35, _toDisplayString(_ctx.formatUtcDate(_ctx.orderInvoicingInfo.createdDate, 'dd MMMM yyyy t a ZZZZ')), 1),
                                  _createTextVNode(" " + _toDisplayString(_ctx.getOrderInvoicingMessage()), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.order.shipGroups?.length)
                  ? (_openBlock(), _createElementBlock("h4", _hoisted_36, _toDisplayString(_ctx.translate('Other shipments in this order')), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_37, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.order.shipGroups, (shipGroup) => {
                    return (_openBlock(), _createBlock(_component_ion_card, {
                      key: shipGroup.shipmentId
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_card_header, null, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _createVNode(_component_ion_card_subtitle, { class: "overline" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.getfacilityTypeDesc(shipGroup.facilityTypeId)), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ion_card_title, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(shipGroup.facilityName), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createTextVNode(" " + _toDisplayString(shipGroup.shipGroupSeqId), 1)
                            ]),
                            _createVNode(_component_ion_badge, {
                              color: shipGroup.category ? 'primary' : 'medium'
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(shipGroup.category ? shipGroup.category : _ctx.translate('Pending allocation')), 1)
                              ]),
                              _: 2
                            }, 1032, ["color"])
                          ]),
                          _: 2
                        }, 1024),
                        (shipGroup.carrierPartyId)
                          ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.getPartyName(shipGroup.carrierPartyId)) + " ", 1),
                                _createVNode(_component_ion_label, { slot: "end" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(shipGroup.trackingCode), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_icon, {
                                  slot: "end",
                                  icon: _ctx.locateOutline
                                }, null, 8, ["icon"])
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true),
                        (shipGroup.shippingInstructions)
                          ? (_openBlock(), _createBlock(_component_ion_item, {
                              key: 1,
                              color: "light",
                              lines: "none"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("p", _hoisted_38, _toDisplayString(_ctx.translate("Handling Instructions")), 1),
                                    _createElementVNode("p", null, _toDisplayString(shipGroup.shippingInstructions), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(shipGroup.items, (item) => {
                          return (_openBlock(), _createElementBlock("div", { key: item }, [
                            _createVNode(_component_ion_item, { lines: "none" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_DxpShopifyImg, {
                                      src: _ctx.getProduct(item.productId).mainImageUrl,
                                      size: "small"
                                    }, null, 8, ["src"])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("p", _hoisted_39, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(item.productId))), 1),
                                    _createTextVNode(" " + _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) : _ctx.getProduct(item.productId).productName) + " ", 1),
                                    (_ctx.isKit(item))
                                      ? (_openBlock(), _createBlock(_component_ion_badge, {
                                          key: 0,
                                          color: "dark"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("Kit")), 1)
                                          ]),
                                          _: 1
                                        }))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createElementVNode("div", _hoisted_40, [
                                  (_ctx.getProductStock(item.productId, item.facilityId).quantityOnHandTotal)
                                    ? (_openBlock(), _createBlock(_component_ion_note, {
                                        key: 0,
                                        slot: "end"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.getProductStock(item.productId, item.facilityId).quantityOnHandTotal) + " " + _toDisplayString(_ctx.translate('pieces in stock')), 1)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    : (_openBlock(), _createBlock(_component_ion_button, {
                                        key: 1,
                                        slot: "end",
                                        fill: "clear",
                                        size: "small",
                                        onClick: _withModifiers(($event: any) => (_ctx.fetchProductStock(item.productId, item.facilityId)), ["stop"])
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_icon, {
                                            color: "medium",
                                            slot: "icon-only",
                                            icon: _ctx.cubeOutline
                                          }, null, 8, ["icon"])
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"])),
                                  (_ctx.isKit(item))
                                    ? (_openBlock(), _createBlock(_component_ion_button, {
                                        key: 2,
                                        slot: "end",
                                        fill: "clear",
                                        size: "small",
                                        onClick: _withModifiers(($event: any) => (_ctx.fetchKitComponent(item, true)), ["stop"])
                                      }, {
                                        default: _withCtx(() => [
                                          (item.showKitComponents)
                                            ? (_openBlock(), _createBlock(_component_ion_icon, {
                                                key: 0,
                                                color: "medium",
                                                slot: "icon-only",
                                                icon: _ctx.chevronUpOutline
                                              }, null, 8, ["icon"]))
                                            : (_openBlock(), _createBlock(_component_ion_icon, {
                                                key: 1,
                                                color: "medium",
                                                slot: "icon-only",
                                                icon: _ctx.listOutline
                                              }, null, 8, ["icon"]))
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"]))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 2
                            }, 1024),
                            (item.showKitComponents && _ctx.getProduct(item.productId)?.productComponents)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getProduct(item.productId).productComponents, (productComponent, index) => {
                                    return (_openBlock(), _createBlock(_component_ion_card, { key: index }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_item, { lines: "none" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_DxpShopifyImg, {
                                                  src: _ctx.getProduct(productComponent.productIdTo).mainImageUrl,
                                                  size: "small"
                                                }, null, 8, ["src"])
                                              ]),
                                              _: 2
                                            }, 1024),
                                            _createVNode(_component_ion_label, null, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", _hoisted_42, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(productComponent.productIdTo))), 1),
                                                _createTextVNode(" " + _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(productComponent.productIdTo)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(productComponent.productIdTo)) : productComponent.productIdTo) + " ", 1),
                                                _createElementVNode("p", null, _toDisplayString(_ctx.getFeature(_ctx.getProduct(productComponent.productIdTo).featureHierarchy, '1/COLOR/')) + " " + _toDisplayString(_ctx.getFeature(_ctx.getProduct(productComponent.productIdTo).featureHierarchy, '1/SIZE/')), 1)
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  }), 128))
                                ]))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_43, [
                _createElementVNode("p", null, _toDisplayString(_ctx.translate("Unable to fetch the order details. Either the order has been shipped or something went wrong. Please try again after some time.")), 1)
              ]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}